.App {
  text-align: left;
}

.text_container {
  width: 65%;
  margin-left: 17.25%
}

.button_container_container {
  background: rgb(220,220,220);
  padding: 20px;
  margin-top: 3vh;
  border-radius: 10px;
  box-shadow: 0px 0px 3px 3px grey
}

.button_container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.button_container:first-child {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px
}

button {
  background: #93002C;
  color: white;
  border: none;
  font-size: 20px;
  border-radius: 10px;
  width: 300px;
  padding: 10px;
  transition: all 0.2s
}

button:hover {
  background:#93002c91;
  transform: scale(1.05);
  cursor: pointer
}

h1 {
  text-align: center;
  color: #00635B
}


.title_container {
  position: relative;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title_container img {
  position: absolute;
  left: 0
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
